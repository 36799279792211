<template>
  <LoadingComponent v-if="loading" :height="true" />
  <div v-else class="day-summary-poseur">
    <table class="grid" :style="computedStyle">
      <colgroup>
        <col width="15px" />
        <col width="50px" />
        <!-- <col width="60px" />
        <col width="68px" /> -->
        <col width="20px" />
        <col width="20px" />
        <col width="20px" />
        <!-- <col width="33px" />
        <col width="33px" />

        <col width="27px" />
        <col width="27px" />
        <col width="27px" />
        <col width="27px" />
        <col width="27px" />
        <col width="27px" />
        <col width="27px" />
        <col width="27px" />
        <col width="27px" />
        <col width="27px" />

        <col width="33px" /> -->
        <!-- 
        <col width="25px" />
        <col width="25px" />
        <col width="25px" />
        <col width="70px" /> -->
      </colgroup>
      <tr v-if="!estEcranBureauHeader">
        <div class="color-title" :style="computedStyle">
          {{ technician.name }}
        </div>
      </tr>
      <tr>
        <th>H</th>
        <th>Lead</th>
        <!-- <th>Intervenants</th> -->
        <th title="Code postal">CP</th>
        <th>Trajet</th>
        <th>RDV</th>
        <!-- <th class="grey-header" title="Total d'heures">T(h)</th>

        <th class="blue-header" title="ECS">ECS</th>
        <th class="blue-header" title="R/O">R/O</th>
        <th class="blue-header" title="R/R">R/R</th>
        <th class="blue-header" title="VMC">VMC</th>
        <th class="blue-header" title="S">S</th>
        <th class="blue-header" title="D">D</th>
        <th class="blue-header" title="P">P</th>
        <th class="blue-header" title="R+">R+</th>
        <th class="blue-header" title="M+">M+</th>
        <th class="blue-header" title="ITE">ITE</th>
        <th class="grey-header" title="Total des surfaces">T(m²)</th> -->
        <!-- <th class="icon-wrapper w13" title="Camion">
          <font-awesome-icon class="icon" :icon="['fas', 'truck']" />
        </th> -->
        <!-- <th class="icon-wrapper w14" title="Dossier">
          <font-awesome-icon class="icon" :icon="['fas', 'folder-open']" />
        </th>
        <th class="icon-wrapper w15" title="Photo">
          <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
        </th>
        <th class="icon-wrapper w15 pt-1" title="Confirmé">
          <WorkerIcon :height="'15px'" :width="'15px'" :fill="'#5e5e5e'" />
        </th>
        <th>Stade</th> -->
      </tr>
      <draggable
        style="width: 100%;
    display: contents;"
        @change="dragDropPlanningAMO($event, myList)"
        v-model="myList"
        group="people"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
        @clone="removechoose($event)"
        :disabled="getProjectsProcessing"
        @unchoose="endRemove($event)"
      >
        <tr
          v-for="(row, index) in computedDay.dayRows"
          :key="row.id + technician.name + index"
          :class="{
            off: !row.active,
            disabled: row.projet_tech && row.projet_tech.disabled === 1,
            simulatable: row.simulatable,
            duplicate:
              row.projet_tech &&
              row.projet_tech.project_id &&
              row.projet_tech.duplicate == 1
          }"
        >
          <!-- {{
            row.colorBlue
          }} -->
          <!-- {{row.projet_tech}} -->
          <!-- {{
            index
          }}-->
          <!-- HEURE -->
          <td
            v-if="row && row.colorBlue"
            class="light-grey-column-hours "
            :class="{
              move:
                row.projet_tech &&
                row.projet_tech.project_id &&
                row.projet_tech.duplicate == 0,
              'color-rdv':
                row.projet_tech &&
                row.projet_tech.project_id &&
                row.projet_tech.duplicate == 1
                  ? false
                  : true,
              handle:
                row && row.projet_tech && row.projet_tech.duplicate == 0
                  ? true
                  : false
            }"
            @click="
              row.projet_tech &&
              row.projet_tech.project_id &&
              row.projet_tech.duplicate == 1
                ? false
                : openModalInjection(row)
            "
            v-b-tooltip.hover
            :title="popoverData(row.projet_tech)"
          >
            <span
              :class="{
                visibility: (row.simulatable && simulated) || row.loading
              }"
            >
              {{
                row.projet_tech && row.projet_tech.hour_trav_planif
                  ? computedHours(row.projet_tech.hour_trav_planif)
                  : '-'
              }}
            </span>
            <div
              v-if="row.loading"
              class="init-loading three-dots-loading color"
            ></div>
            <div
              v-if="row.simulatable && simulated"
              class="action"
              @click="resetSimulation"
            >
              <font-awesome-icon class="icon" icon="minus-square" />
            </div>
          </td>
          <td
            v-else
            class="light-grey-column-hours"
            :class="{
              move:
                row.projet_tech &&
                row.projet_tech.project_id &&
                row.projet_tech.duplicate == 0,
              'color-rdv':
                row.projet_tech &&
                row.projet_tech.project_id &&
                row.projet_tech.duplicate == 0,
              handle:
                row && row.projet_tech && row.projet_tech.duplicate == 0
                  ? true
                  : false
            }"
            @click="
              row.projet_tech &&
              row.projet_tech.project_id &&
              row.projet_tech.duplicate == 0
                ? openUpdateInjection(row, null)
                : false
            "
            v-b-tooltip.hover
            :title="popoverData(row.projet_tech)"
          >
            <span
              :class="{
                visibility: (row.simulatable && simulated) || row.loading
              }"
            >
              {{
                row.projet_tech && row.projet_tech.hour_trav_planif
                  ? computedHours(row.projet_tech.hour_trav_planif)
                  : '-'
              }}
            </span>
            <div
              v-if="row.loading"
              class="init-loading three-dots-loading color"
            ></div>
            <div
              v-if="row.simulatable && simulated"
              class="action"
              @click="resetSimulation"
            >
              <font-awesome-icon class="icon" icon="minus-square" />
            </div>
          </td>
          <!-- LEAD -->
          <td class="commentable">
            <!-- <TooltipComment
              v-if="row.projet_tech && row.projet_tech.comments"
              placement="right-start"
              :lead="row.projet_tech"
              :canEdit="canEdit"
            > -->
            <TooltipLink
              v-if="row.projet_tech && row.projet_tech.project_id"
              :name="
                row.projet_tech.title_contact +
                  ' (' +
                  computedValueDepartement(row.projet_tech.zipCode) +
                  ')'
              "
              :dealId="row.projet_tech.project_id"
              :lead="row.projet_tech"
              @changeSav="refreshPlanning"
            >
              <div class="title-container">
                <!-- :color="
                      row.projet_tech.sav === 1
                        ? 'blue'
                        : colorStadeTech[row.index]
                    " -->
                <ColorTag
                  :style="{
                    left: '2px',
                    bottom: '2px',
                    top: '2px',
                    width: '10px',
                    'border-radius': '2px',
                    background: technician.color_pose
                  }"
                />
                <span
                  v-if="
                    row.projet_tech.comments && row.projet_tech.comments.count
                  "
                  class="comment-tag"
                ></span>
                <span>{{ row.projet_tech.title_contact }} </span>
                <p v-if="row.projet_tech && row.projet_tech.zipCode">
                  ({{ computedValueDepartement(row.projet_tech.zipCode) }})
                </p>
              </div>
            </TooltipLink>
            <!-- </TooltipComment> -->
          </td>

          <!-- INTERVENANT -->
          <!-- <td
          class="text-left pl-1 pr-1"
          :title="row.lead ? row.lead.title_agent : ''"
        >
          <TooltipEditIntervenants
            v-if="row.lead"
            :canEdit="canEdit && !row.lead.disabled"
            :lead="row.lead"
          >
          </TooltipEditIntervenants>
          {{
            row.lead && row.lead.title_agent
              ? row.lead.title_agent.split(' ')[0]
              : ''
          }}
        </td> -->
          <!-- CODE POSTAL -->
          <td>
            {{ row.projet_tech ? row.projet_tech.zipCode : '' }}
            <!-- <input
              v-if="row.simulatable"
              :id="technician.id"
              type="number"
              :value="simulated ? simulation[row.index].zipCode : null"
              @change="handleInputChange($event, 'zipCode', row.index)"
            /> -->
          </td>

          <!-- Trajet -->
          <td>
            {{
              row.simulatable && simulated
                ? simulation[row.index].trajet_time_tech
                : row.projet_tech
                ? row.projet_tech.trajet_time_tech
                : null | time
            }}
          </td>
          <!-- RDV => TVX -->
          <td>
            {{
              row.simulatable && simulated
                ? simulation[row.index].pose_time_tech
                : row.projet_tech
                ? row.projet_tech.pose_time_tech
                : null | time
            }}
          </td>
          <!-- TIME PAR HEURE -->
          <!-- <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].trajet_pose_tech_h_sum
              : row.lead
              ? row.lead.trajet_pose_tech_h_sum
              : null | time
          }}
        </td> -->
          <!-- SURFACES -->
          <!-- <td>{{ '' }}</td>
        <td>{{ '' }}</td>
        <td>{{ '' }}</td>
        <td>{{ '' }}</td>

        <td
          :class="{
            colorable: row.lead && row.lead.surface_souffle > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_souffle"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_souffle && row.lead.surface_souffle_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_souffle +
                    ', Posé: ' +
                    row.lead.surface_souffle_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_souffle > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_souffle_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_souffle }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_souffle : null"
            @change="handleInputChange($event, 'surface_souffle', row.index)"
          />
        </td> -->
          <!-- <td
          :class="{
            colorable: row.lead && row.lead.surface_deroule > 0,
            editable: canEdit
          }"
        >
          {{ row.lead ? row.lead.surface_deroule : '' }}
          <TooltipEdit
            v-if="row.lead"
            field="surface_deroule"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_deroule && row.lead.surface_deroule_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_deroule +
                    ', Posé: ' +
                    row.lead.surface_deroule_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_deroule > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_deroule_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_deroule }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_deroule : null"
            @change="handleInputChange($event, 'surface_deroule', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_plafond > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_plafond"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_plafond && row.lead.surface_plafond_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_plafond +
                    ', Posé: ' +
                    row.lead.surface_plafond_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_plafond > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_plafond_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_plafond }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_plafond : null"
            @change="handleInputChange($event, 'surface_plafond', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_rampant > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_rampant"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_rampant && row.lead.surface_rampant_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_rampant +
                    ', Posé: ' +
                    row.lead.surface_rampant_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_rampant > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_rampant_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_rampant }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_rampant : null"
            @change="handleInputChange($event, 'surface_rampant', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_mur > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_mur"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_mur && row.lead.surface_mur_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_mur +
                    ', Posé: ' +
                    row.lead.surface_mur_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_mur > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_mur_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_mur }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_mur : null"
            @change="handleInputChange($event, 'surface_mur', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_iti > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead"
            field="surface_iti"
            :weekId="month_id"
            :lead="row.lead"
            :canEdit="canEdit && !row.lead.disabled"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_iti && row.lead.surface_iti_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_iti +
                    ', Posé: ' +
                    row.lead.surface_iti_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_iti > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeTech[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_iti_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_iti }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable"
            type="number"
            :value="simulated ? simulation[row.index].surface_iti : null"
            @change="handleInputChange($event, 'surface_iti', row.index)"
          />
        </td> -->
          <!-- TOATAL DES SURFACE PAR METRE CARRE -->
          <!-- <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].total
              : row.lead
              ? Math.round(
                  (parseFloat(row.lead.total) + Number.EPSILON) * 100
                ) / 100
              : ''
          }}
        </td> -->
          <!--  CALCUL CAMION -->
          <!-- <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].camion
              : row.lead
              ? row.lead.camion
                ? Math.round(row.lead.camion * 100) + '%'
                : ''
              : ''
          }}
        </td> -->
          <!--  ICON CHECKBOX-->
          <!-- <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="dossier[row.id]"
            @change="handleCheckboxChange('dossier', row)"
          />
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="photo[row.id]"
            @change="handleCheckboxChange('photo', row)"
          />
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="confirmation[row.id]"
            @change="handleCheckboxChange('confirmation', row)"
          />
        </td> -->
          <!-- STADE  -->
          <!-- <td class="editable">
          <TooltipPipeLine
            v-if="row.lead"
            :lead="row.lead"
            placement="auto"
            :canEdit="canEdit && !row.lead.disabled"
            @changeStage="refreshPlanning"
          >
            <Stage v-if="row.lead" :stage_id="row.lead.cstage_id" />
          </TooltipPipeLine>
          <b-form-checkbox
            v-else-if="canEdit && !computedDay.optimised"
            switch
            :checked="row.active"
            @change="handleSwitchChange(row)"
          ></b-form-checkbox>
        </td> -->
        </tr>
      </draggable>

      <!-- <tr>
        <th rowspan="2" class="off"></th>
        <th>CA</th>
        <th colspan="2">Planifié/Posé</th>
        <th>Trajet</th>
        <th>TVX</th>
        <th>T(h)</th>
        <td class="light-grey-column">
          {{ '' }}
        </td>
        <td class="light-grey-column">
          {{ '' }}
        </td>
        <td class="light-grey-column">
          {{ '' }}
        </td>
        <td class="light-grey-column">
          {{ '' }}
        </td>

        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_s_planifie
              : computedDay.total_s_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_d_planifie
              : computedDay.total_d_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_p_planifie
              : computedDay.total_p_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_r_planifie
              : computedDay.total_r_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{
            simulated
              ? simulation.total_m_planifie
              : computedDay.total_m_planifie
          }}
        </td>
        <td class="light-grey-column">
          {{ simulated ? simulation.iti_day : computedDay.iti_day }}
        </td>

        <td>
          {{
            simulated
              ? simulation.total_total_m_planifie
              : computedDay.total_total_m_planifie
          }}
        </td>
        <th colspan="3">Planifié</th>
        <th
          rowspan="2"
          class="action"
          :class="{ optimised: computedDay.optimised }"
          @click="toggleOptimised"
        >
          {{ computedDay.optimised ? 'Optimisé' : 'Non optimisé' }}
        </th>
      </tr>
      <tr>
        <td>
          {{ computedDay.ca_d }}
        </td>
        <td colspan="2">
          {{
            computedDay.pose_planifie_d
              ? computedDay.pose_planifie_d.toFixed(2) + '%'
              : ''
          }}
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_trajet
                : computedDay.total_trajet | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated ? simulation.total_pose : computedDay.total_pose | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_total_h
                : computedDay.total_total_h | time
            }}
          </span>
        </td>

        <td class="light-grey-column">
          {{ '' }}
        </td>
        <td class="light-grey-column">
          {{ '' }}
        </td>
        <td class="light-grey-column">
          {{ '' }}
        </td>
        <td class="light-grey-column">
          {{ '' }}
        </td>

        <td class="light-grey-column">
          {{ computedDay.total_s_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_d_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_p_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_r_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_m_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_iti_pose }}
        </td>

        <td>
          {{ computedDay.total_total_m_pose }}
        </td>
        <th colspan="3">Posé</th>
      </tr> -->
    </table>

    <b-modal
      id="modal-center"
      ref="modal-center"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="content-injection">
        <div class="margin-div">
          Vous voulez confirmer l'injection de ce rendez vous dans la liste
          <span class="gras">{{ technician.name }}</span>
        </div>
        <div class="flex margin">
          <div class="flex">
            <div>Date de début</div>
            <!-- <VueCtkDateTimePicker
              id="hour-debut"
              v-model="timeDebut"
              :no-label="true"
              format="HH:mm"
              formatted="LT"
              label="HH:mm"
              locale="fr"
              :only-time="true"
              :disabled-hours="hoursDisable"
              :no-button="false"
              :auto-close="false"
              @input="changerHours"
              :disabled="warning && warning.length ? true : false"
            /> -->
            <VueCtkDateTimePicker
              :no-weekends-days="false"
              :disabled-weekly="[0]"
              format="DD/MM/YYYY HH:mm"
              formatted="lll"
              :disabled-hours="[
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '21',
                '22',
                '23'
              ]"
              v-model="selectedDateDebut"
              :no-label="true"
              locale="fr"
              :no-header="true"
              :no-button="false"
              :auto-close="false"
              label="DD/MM/YYYY HH:mm"
              :max-date="'2024/01/01'"
              :min-date="'2019/01/01'"
              button-now-translation="Maintenant"
              @input="changeValueDate(selectedDateDebut)"
              :disabled="warning && warning.length ? true : false"
            />
          </div>
          <div class="flex">
            <div>Date de fin</div>
            <!-- <VueCtkDateTimePicker
              id="hour-fin"
              v-model="timeFin"
              :no-label="true"
              format="HH:mm"
              locale="fr"
              formatted="LT"
              label="HH:mm"
              :only-time="true"
              :disabled-hours="[
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '21',
                '22',
                '23'
              ]"
              :no-button="false"
              :auto-close="false"
              :disabled="warning && warning.length ? true : false"
            /> -->
            <VueCtkDateTimePicker
              :no-weekends-days="false"
              :disabled-weekly="[0]"
              format="DD/MM/YYYY HH:mm"
              formatted="lll"
              :disabled-hours="[
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '21',
                '22',
                '23'
              ]"
              v-model="selectedDateFin"
              :no-label="true"
              locale="fr"
              :no-header="true"
              :no-button="false"
              :auto-close="false"
              label="DD/MM/YYYY HH:mm"
              :max-date="'2024/01/01'"
              :min-date="'2019/01/01'"
              button-now-translation="Maintenant"
              :disabled="warning && warning.length ? true : false"
            />
            .
          </div>
        </div>
      </div>
      <div class="message">
        <div v-if="getProjectsProcessing" class="loading m-2 pt-3">
          Chargement en cours...
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
        <div v-if="warning" class="warning">
          <ul v-if="Array.isArray(warning)" class="mr-3">
            <li v-for="(e, index) in warning" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ warning }}</span>
        </div>
        <div v-if="getProjectsError" class="error pt-3">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <div class="action">
        <b-button
          size="sm"
          v-if="warning && warning.length"
          variant="outline-secondary"
          @click="cancelModalWarning"
        >
          Fermer
        </b-button>
        <b-button
          size="sm"
          v-else
          variant="outline-secondary"
          @click="cancelModal"
        >
          Annuler
        </b-button>
        <b-button
          v-if="row && row.colorBlue && (!warning || !warning.length)"
          type="submit"
          size="sm"
          variant="success"
          class="ml-2"
          @click="injecterRDVParFileProjet"
        >
          Injecter RDV AMO
        </b-button>
        <b-button
          v-if="row && !row.colorBlue && (!warning || !warning.length)"
          type="submit"
          size="sm"
          variant="success"
          class="ml-2"
          @click="InjecterRdvAMO"
        >
          Injecter RDV AMO
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-confirm-rdv"
      ref="modal-confirm-rdv"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div>
        Un rendez vous est injecté déjà à cette heure , merci de choisir une
        autre.
      </div>
      <b-button
        class="postion-btn"
        size="sm"
        variant="outline-secondary"
        @click="cancelModalConfirm"
      >
        Fermer
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingComponent from '@/components/ui/LoadingComponent'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import draggable from 'vuedraggable'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
momentDurationFormatSetup(moment)
export default {
  name: 'PlanningPoseurDaySummary',
  components: {
    LoadingComponent,
    draggable,
    VueCtkDateTimePicker,
    // TooltipPipeLine: () => import('@/components/ui/TooltipPipeLine'),
    // TooltipComment: () => import('@/components/ui/TooltipComment'),
    // TooltipEdit: () => import('@/components/ui/TooltipEdit'),
    // TooltipEditIntervenants: () =>
    //   import('@/components/ui/TooltipEditIntervenants'),
    TooltipLink: () => import('@/components/ui/TooltipLink'),
    ColorTag: () => import('@/components/ui/ColorTag')
    // Stage: () => import('@/components/Stage'),
    // WorkerIcon: () => import('@/components/ui/WorkerIcon.vue')
  },
  props: {
    day: {
      required: true
    },
    technician: {
      required: true
    },
    month_id: {
      required: true
    },
    depot_zipCode: {
      required: false
    },
    user_zipCode: {
      required: false
    },
    canEdit: {
      default: false
    },
    loading: {
      default: false
    },
    planning: { required: false },
    filialeAgence: {
      required: true
    },
    dayCalander: {
      required: true
    },
    estEcranBureauHeader: {
      required: false
    }
  },
  data() {
    return {
      dossier: [],
      photo: [],
      confirmation: [],
      simulation: {
        1: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        2: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        3: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        4: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        5: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0,
        total_s_planifie: 0,
        total_d_planifie: 0,
        total_r_planifie: 0,
        total_m_planifie: 0,
        total_p_planifie: 0,
        total_pignon_planifie: 0,
        total_vs_planifie: 0,
        iti_day: 0,
        total_total_m_planifie: 0
      },
      days_Diff: 0,
      days_Diff_1: 0,
      simulated: false,
      copyLoading: false,
      selectedDateDebut: null,
      selectedDateFin: null,
      selectedLastDateDabut: null,
      selectedLastDateFin: null,
      timeDebut: null,
      timeFin: null,
      hoursDisable: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
      ],
      bodyRdv: null,
      movedRdv: null,
      removed: null,
      row: null,
      dataInjecterRDV: null,
      error: [],
      warning: []
    }
  },
  methods: {
    ...mapActions([
      'updateFieldOptimise',
      'changeCEEPhotoMeeting',
      'updateRowStatus',
      'getSimulatedTrajet',
      'getSimulatedTotalSurface',
      'getSimulatedPose',
      'getSimulatedCamion',
      'fetchPlanning',
      'resetErrorProject',
      'updateProject',
      'fetchObjectDayRowPlanning'
    ]),
    changeValueDate(dateDebut) {
      if (
        JSON.parse(localStorage.getItem('pose')) &&
        JSON.parse(localStorage.getItem('pose')).date_rdv == null
      ) {
        this.days(dateDebut)
      } else {
        let daysDiff =
          this.days_Diff_1 !== 0
            ? this.days_Diff_1
            : this.days_Diff !== 0
            ? this.days_Diff
            : 0
        if (
          dateDebut &&
          dateDebut != this.selectedLastDateDabut &&
          daysDiff != 0
        ) {
          this.selectedDateDebut = dateDebut
          let DateDebutFormat = new Date(
            moment(dateDebut, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD')
          )
          this.selectedLastDateDabut = dateDebut
          let test = this.addDays(DateDebutFormat, daysDiff)
          this.selectedLastDateDabut = dateDebut
          // ********************* Change hours *******************
          let hourFin = ''
          if (this.selectedDateDebut) {
            let hour = this.selectedDateDebut.split(' ')[1].split(':')[0]
            let minute = this.selectedDateDebut.split(' ')[1].split(':')[1]
            let sommeHour = Number(hour)
            if (sommeHour == 20) {
              // hourFin = `${sommeHour}:${minute}`
              hourFin = `${sommeHour}:59`
            } else {
              sommeHour = sommeHour + 1
              hourFin = `${sommeHour}:${minute}`
            }
          }
          // ************ Date Fin **********************************
          let formatDateFin = moment(test).format('DD/MM/YYYY')
          this.selectedDateFin = `${formatDateFin} ${hourFin}`
        } else if (daysDiff == 0) {
          this.days(dateDebut)
        }
      }
    },
    addDays(dateDebut, days) {
      let count = 0
      let dateFin = ''
      while (count < days) {
        dateFin = new Date(dateDebut.setDate(dateDebut.getDate() + 1))
        if (dateFin.getDay() == 0) {
          days = days + 1
        }
        count++
      }
      return dateFin
    },
    days(dateDebut) {
      if (dateDebut) {
        this.selectedDateDebut = dateDebut
        let dateDebutFormat = moment(dateDebut, 'DD/MM/YYYY HH:mm').format(
          'YYYY/MM/DD HH:mm'
        )
        // hour debut
        let hour_debut = dateDebutFormat.split(' ')[1]
        // add hour fin
        let hour_fin = ''
        if (hour_debut) {
          let hour = hour_debut.split(':')[0]
          let minute = hour_debut.split(':')[1]
          let sommeHour = Number(hour)
          if (sommeHour == 20) {
            // hour_fin = `${sommeHour}:${minute}`
            hour_fin = `${sommeHour}:59`
          } else {
            sommeHour = sommeHour + 1
            hour_fin = `${sommeHour}:${minute}`
          }
        }
        this.selectedDateFin = `${dateDebut.split(' ')[0]} ${hour_fin}`
      }
    },
    async InjecterRdvAMO() {
      this.resetErrorProject()
      this.error = []
      this.warning = []
      if (this.bodyRdv) {
        if (this.bodyRdv && this.bodyRdv.field && this.bodyRdv.field.length) {
          for (let index = 0; index < this.bodyRdv.field.length; index++) {
            if (this.bodyRdv.field[index].key == 'fin_rdv') {
              this.bodyRdv.field[index].value = this.selectedDateFin
                ? moment(this.selectedDateFin, 'DD/MM-YYYY HH:mm').format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                : null
            }
            if (this.bodyRdv.field[index].key == 'date_rdv') {
              this.bodyRdv.field[index].value = this.selectedDateDebut
                ? moment(this.selectedDateDebut, 'DD/MM-YYYY HH:mm').format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                : null
            }

            if (this.bodyRdv.field[index].key == 'hour_rdv') {
              this.bodyRdv.field[index].value = this.selectedDateDebut
                ? moment(this.selectedDateDebut.split(' ')[1], 'HH:mm').format(
                    'HH:mm'
                  )
                : null
            }
          }
        }
        const validate = this.validateInjection(this.bodyRdv.field)
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        if (this.row && this.row.projet_tech) {
          this.bodyRdv.entity = this.row.projet_tech.project_id
        }
        await this.updateProject(this.bodyRdv).then(async () => {
          if (this.getProjectResponseUpdate) {
            // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
            await this.fetchPlanning({
              planning: 'planning-technique',
              day_nbr: this.filialeAgence.day,
              tech_ids: [this.technician.id]
            })
            if (
              JSON.parse(localStorage.getItem('removed')) &&
              JSON.parse(localStorage.getItem('removed')).amo
            ) {
              this.fetchPlanning({
                planning: 'planning-technique',
                day_nbr: this.filialeAgence.day,
                tech_ids: [JSON.parse(localStorage.getItem('removed')).amo]
              })
            }
            this.$nextTick(() => {
              localStorage.removeItem('removed')
              if (this.getProjectsWarning) {
                this.warning = this.getProjectsWarning
              } else {
                this.$refs['modal-center'].hide()
              }
            })
          }
        })
      } else {
        const payload = {}
        let field_group = [
          {
            key: 'date_rdv',
            value: this.selectedDateDebut
              ? moment(this.selectedDateDebut, 'DD/MM-YYYY HH:mm').format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              : null
          },
          {
            key: 'hour_rdv',
            value: this.selectedDateDebut
              ? moment(this.selectedDateDebut.split(' ')[1], 'HH:mm').format(
                  'HH:mm'
                )
              : null
          },
          {
            key: 'fin_rdv',
            value: this.selectedDateFin
              ? moment(this.selectedDateFin, 'DD/MM-YYYY HH:mm').format(
                  'YYYY-MM-DD HH:mm:ss'
                )
              : null
          },
          // FILIALE + AGENCE +AMO
          {
            key: 'technicien_id',
            value:
              this.technician && this.technician.id
                ? parseInt(this.technician.id)
                : null
          },
          {
            key: 'agence_tech_id',
            value:
              this.filialeAgence && this.filialeAgence.agence
                ? parseInt(this.filialeAgence.agence)
                : null
          },
          {
            key: 'team_tech_id',
            value:
              this.filialeAgence && this.filialeAgence.filiale
                ? parseInt(this.filialeAgence.filiale)
                : null
          },
          //
          {
            key: 'logistique_travaux',
            value:
              this.row && this.row.projet_tech && this.row.projet_tech.travaux
                ? this.row.projet_tech.travaux
                : null
          },
          {
            key: 'type_travaux',
            value:
              this.row &&
              this.row.projet_tech &&
              this.row.projet_tech.type_travaux
                ? this.row.projet_tech.type_travaux
                : null
          },
          {
            key: 'type_isolation',
            value:
              this.row &&
              this.row.projet_tech &&
              this.row.projet_tech.type_isolation
                ? this.row.projet_tech.type_isolation
                : null
          }
        ]
        payload.entity = this.row.projet_tech.project_id
        payload.field = field_group
        payload.value = field_group
        //  pour tester ce fields dans file projet js
        payload.field_group = true
        payload.planningAmo = true
        const validate = this.validateInjection(payload.field)
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        await this.updateProject(payload).then(async () => {
          if (this.getProjectResponseUpdate) {
            localStorage.removeItem('removed')
            // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
            this.fetchPlanning({
              planning: 'planning-technique',
              day_nbr: this.filialeAgence.day,
              tech_ids: [this.technician.id]
            })
            if (this.getProjectsWarning) {
              this.warning = this.getProjectsWarning
            } else {
              this.$refs['modal-center'].hide()
              localStorage.removeItem('pose')
            }
            if (this.day && this.day.dayRows && this.day.dayRows.length) {
              for (let index = 0; index < this.planning.length; index++) {
                if (
                  this.planning[index].days &&
                  this.planning[index].days.length
                ) {
                  for (let t = 0; t < this.planning[index].days.length; t++) {
                    for (
                      let j = 0;
                      j < this.planning[index].days[t].dayRows.length;
                      j++
                    ) {
                      this.planning[index].days[t].dayRows[j].colorBlue = false
                    }
                  }
                }
              }
            }
          }
        })
      }
    },
    cancelModal() {
      if (this.movedRdv && this.movedRdv.moved) {
        for (let index = 0; index < this.planning.length; index++) {
          if (
            this.planning[index] &&
            this.planning[index].id == this.technician.id
          ) {
            {
              if (
                this.planning[index].days &&
                this.planning[index].days.length
              ) {
                for (let t = 0; t < this.planning[index].days.length; t++) {
                  for (
                    let j = 0;
                    j < this.planning[index].days[t].dayRows.length;
                    j++
                  ) {
                    if (
                      this.planning[index].days[t].date == this.dataInjecterRDV
                    ) {
                      this.planning[index].days[
                        t
                      ].dayRows = this.movedRdv.DayRow.sort(
                        (a, b) => a.index - b.index
                      )
                      break
                    }
                  }
                }
              }
            }
            break
          }
        }
      }
      if (
        this.movedRdv &&
        this.movedRdv.added &&
        this.movedRdv.DayRow &&
        this.movedRdv.DayRow.length
      ) {
        if (this.movedRdv.added.newIndex != 3) {
          this.movedRdv.DayRow[
            this.movedRdv.added.newIndex - 1
          ].projet_tech = []
        } else {
          this.movedRdv.DayRow[1].projet_tech = []
        }
      }
      if (JSON.parse(localStorage.getItem('removed'))) {
        for (let index = 0; index < this.planning.length; index++) {
          if (
            this.planning[index] &&
            this.planning[index].id ==
              JSON.parse(localStorage.getItem('removed')).amo
          ) {
            {
              if (
                this.planning[index].days &&
                this.planning[index].days.length
              ) {
                for (let t = 0; t < this.planning[index].days.length; t++) {
                  for (
                    let j = 0;
                    j < this.planning[index].days[t].dayRows.length;
                    j++
                  ) {
                    if (
                      this.planning[index].days[t].date ==
                        JSON.parse(localStorage.getItem('removed')).element
                          .planningDate &&
                      this.planning[index].days[t].dayRows[j].index ==
                        JSON.parse(localStorage.getItem('removed')).oldIndex
                    ) {
                      this.fetchPlanning({
                        planning: 'planning-technique',
                        day_nbr: this.filialeAgence.day,
                        tech_ids: [
                          JSON.parse(localStorage.getItem('removed')).amo
                        ]
                      })
                      // this.planning[index].days[t].dayRows[
                      //   j
                      // ].projet_tech = JSON.parse(
                      //   localStorage.getItem('removed')
                      // ).element.projet_tech
                    }
                  }
                }
              }
            }
            break
          }
        }
      }
      this.$nextTick(() => {
        this.error = []
        this.warning = []
        this.resetErrorProject()
        this.$refs['modal-center'].hide()
        this.selectedDateDebut = null
        this.selectedDateFin = null
      })
    },
    cancelModalConfirm() {
      this.$refs['modal-confirm-rdv'].hide()
      this.resetErrorProject()
      this.error = []
      this.warning = []
    },
    changerHours() {
      if (this.timeDebut) {
        let hour = this.timeDebut.split(':')[0]
        let minute = this.timeDebut.split(':')[1]
        let sommeHour = Number(hour)
        if (sommeHour == 20) {
          this.timeFin = `${sommeHour}:${minute}`
          // this.timeFin = `${sommeHour}:59`
        } else {
          sommeHour = sommeHour + 1
          this.timeFin = `${sommeHour}:${minute}`
        }
      }
    },
    validateInjection(field) {
      const validate = {
        status: 'success',
        errors: [],
        show: true
      }
      if (field && field.length) {
        for (let index = 0; index < field.length; index++) {
          if (field[index].key == 'date_rdv' && field[index].value == null) {
            validate.status = 'error'
            validate.errors.push('Le champ date de début est obligatoire')
          }
          if (field[index].key == 'fin_rdv' && field[index].value == null) {
            validate.status = 'error'
            validate.errors.push('Le champ date de fin est obligatoire')
          }
        }
      }
      return validate
    },
    async injecterRDVParFileProjet() {
      this.resetErrorProject()
      const payload = {}
      let field_group = [
        {
          key: 'date_rdv',
          value: this.selectedDateDebut
            ? moment(this.selectedDateDebut, 'DD/MM-YYYY HH:mm').format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : null
        },
        {
          key: 'hour_rdv',
          value: this.selectedDateDebut
            ? moment(this.selectedDateDebut.split(' ')[1], 'HH:mm').format(
                'HH:mm'
              )
            : null
        },
        {
          key: 'fin_rdv',
          value: this.selectedDateFin
            ? moment(this.selectedDateFin, 'DD/MM-YYYY HH:mm').format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : null
        },
        // FILIALE + AGENCE +AMO
        {
          key: 'technicien_id',
          value:
            this.technician && this.technician.id
              ? parseInt(this.technician.id)
              : null
        },
        {
          key: 'agence_tech_id',
          value:
            this.filialeAgence && this.filialeAgence.agence
              ? parseInt(this.filialeAgence.agence)
              : null
        },
        {
          key: 'team_tech_id',
          value:
            this.filialeAgence && this.filialeAgence.filiale
              ? parseInt(this.filialeAgence.filiale)
              : null
        },
        //
        {
          key: 'logistique_travaux',
          value:
            this.filialeAgence && this.filialeAgence.logistique_travaux
              ? this.filialeAgence.logistique_travaux
              : null
        },
        {
          key: 'type_travaux',
          value:
            this.filialeAgence && this.filialeAgence.type_travaux
              ? this.filialeAgence.type_travaux
              : null
        },
        {
          key: 'type_isolation',
          value:
            this.filialeAgence && this.filialeAgence.type_isolation
              ? this.filialeAgence.type_isolation
              : null
        }
      ]
      payload.entity = JSON.parse(localStorage.getItem('pose')).entity
      payload.field = field_group
      payload.value = field_group
      //  pour tester ce fields dans file projet js
      payload.field_group = true
      payload.planningAmo = true
      // update rdv de td
      const validate = this.validateInjection(payload.field)
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      await this.updateProject(payload)
      this.$nextTick(async () => {
        if (this.getResponseUpdateProjectPlanning) {
          this.fetchPlanning({
            planning: 'planning-technique',
            day_nbr: this.filialeAgence.day,
            tech_ids: [this.technician.id]
          })
          if (this.getProjectsWarning) {
            this.warning = this.getProjectsWarning
          }
          // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
          else {
            this.$refs['modal-center'].hide()
            localStorage.removeItem('pose')
          }
          if (this.day && this.day.dayRows && this.day.dayRows.length) {
            for (let index = 0; index < this.planning.length; index++) {
              if (
                this.planning[index].days &&
                this.planning[index].days.length
              ) {
                for (let t = 0; t < this.planning[index].days.length; t++) {
                  for (
                    let j = 0;
                    j < this.planning[index].days[t].dayRows.length;
                    j++
                  ) {
                    this.planning[index].days[t].dayRows[j].colorBlue = false
                  }
                }
              }
            }
          }
        }
      })
      if (this.getResponseUpdateProjectPlanning) {
        this.resetErrorProject()
        this.deletedInjection()
        this.error = []
        this.warning = []
      }
    },
    async cancelModalWarning() {
      this.$refs['modal-center'].hide()
      this.error = []
      this.warning = []
      this.resetErrorProject()
      this.timeDebut = null
      this.timeFin = null
    },
    deletedInjection() {
      localStorage.removeItem('pose')
    },
    openModalInjection(row) {
      this.resetErrorProject()
      this.error = []
      this.warning = []
      this.row = row
      if (row && row.projet_tech && row.projet_tech.project_id) {
        this.$refs['modal-confirm-rdv'].show()
      } else {
        if (
          JSON.parse(localStorage.getItem('pose')) &&
          JSON.parse(localStorage.getItem('pose')).date_rdv &&
          JSON.parse(localStorage.getItem('pose')).date_fin
        ) {
          if (
            JSON.parse(localStorage.getItem('pose')).date_rdv.split(' ')[0] ==
            row.planningDate
          ) {
            this.selectedDateDebut = JSON.parse(localStorage.getItem('pose'))
              .date_rdv
              ? moment(
                  JSON.parse(localStorage.getItem('pose')).date_rdv,
                  'YYYY-MM-DD HH:mm:ss'
                ).format('DD/MM/YYYY HH:mm')
              : null
            this.selectedDateFin = JSON.parse(localStorage.getItem('pose'))
              .date_fin
              ? moment(
                  JSON.parse(localStorage.getItem('pose')).date_fin,
                  'YYYY-MM-DD HH:mm:ss'
                ).format('DD/MM/YYYY HH:mm')
              : null
          } else {
            let timeDebut = JSON.parse(localStorage.getItem('pose')).date_rdv
              ? JSON.parse(localStorage.getItem('pose')).date_rdv.split(' ')[1]
              : ''
            this.selectedDateDebut = JSON.parse(localStorage.getItem('pose'))
              .date_rdv
              ? moment(
                  JSON.parse(localStorage.getItem('pose')).date_rdv,
                  'YYYY-MM-DD HH:mm:ss'
                ).format('DD/MM/YYYY HH:mm')
              : null

            this.selectedDateFin = JSON.parse(localStorage.getItem('pose'))
              .date_fin
              ? moment(
                  JSON.parse(localStorage.getItem('pose')).date_fin,
                  'YYYY-MM-DD HH:mm:ss'
                ).format('DD/MM/YYYY HH:mm')
              : null
            // ***************
            let test = `${moment(row.planningDate, 'YYYY-MM-DD').format(
              'DD/MM/YYYY'
            )} ${timeDebut}`
            this.selectedLastDateDabut = this.selectedDateDebut
            this.selectedLastDateFin = this.selectedDateFin
            // ******************* la difference entre deux date ************************
            let lastDateDebut = this.selectedDateDebut
              ? moment(this.selectedDateDebut, 'DD/MM/YYYY HH:mm').format(
                  'YYYY/MM/DD'
                )
              : null
            // *******************************
            let firstDateFin = this.selectedDateFin
              ? moment(this.selectedDateFin, 'DD/MM/YYYY HH:mm').format(
                  'YYYY/MM/DD'
                )
              : null
            var date1 = new Date(firstDateFin)
            var date2 = new Date(lastDateDebut)
            // différence de jours
            const diff = this.getBusinessDatesCount(date2, date1)
            this.days_Diff = parseInt(diff) - 1 == 0 ? 0 : parseInt(diff) - 1
            this.days_Diff_1 = 0
            this.changeValueDate(test)
          }
        } else {
          this.selectedDateDebut =
            row && row.planningDate
              ? `${moment(row.planningDate, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY'
                )} ${'07:00'}`
              : null
          this.selectedDateFin =
            row && row.planningDate
              ? `${moment(row.planningDate, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY'
                )} ${'08:00'}`
              : null
        }
        this.selectedLastDateDabut = this.selectedDateDebut
          ? this.selectedDateDebut
          : null
        this.selectedLastDateFin = this.selectedDateFin
          ? this.selectedDateFin
          : null
        this.$refs['modal-center'].show()
      }
    },
    openUpdateInjection(row, dragMoved) {
      this.resetErrorProject()
      this.error = []
      this.warning = []
      this.row = row
      if (dragMoved) {
        // ***************
        let projet = null
        if (dragMoved && dragMoved.DayRow && dragMoved.DayRow.length) {
          projet = dragMoved.DayRow.filter(
            item => item.planningDate != row.planningDate
          )
        }
        // ***************
        let test =
          projet && projet.length
            ? `${moment(projet[0].planningDate, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              )} ${row.projet_tech.hour}`
            : `${moment(row.planningDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${
                row.projet_tech.hour
              }`
        this.selectedLastDateDabut = this.selectedDateDebut
        this.selectedLastDateFin = this.selectedDateFin
        // ******************* la difference entre deux date ************************
        let lastDateDebut = this.selectedDateDebut
          ? moment(this.selectedDateDebut, 'DD/MM/YYYY HH:mm').format(
              'YYYY/MM/DD'
            )
          : null
        // *******************************
        let firstDateFin = this.selectedDateFin
          ? moment(this.selectedDateFin, 'DD/MM/YYYY HH:mm').format(
              'YYYY/MM/DD'
            )
          : null
        var date_1 = new Date(firstDateFin)
        var date_2 = new Date(lastDateDebut)
        // différence de jours
        const diff_1 = this.getBusinessDatesCount(date_2, date_1)
        this.days_Diff_1 = parseInt(diff_1) - 1 == 0 ? 0 : parseInt(diff_1) - 1
        this.days_Diff = 0
        this.changeValueDate(test)
      } else {
        this.selectedDateDebut =
          row && row.projet_tech && row.projet_tech.rdv_le
            ? moment(row.projet_tech.rdv_le, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm'
              )
            : null

        this.selectedDateFin =
          row && row.projet_tech && row.projet_tech.fin_rdv
            ? moment(row.projet_tech.fin_rdv, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm'
              )
            : null

        this.selectedLastDateDabut = this.selectedDateDebut
        this.selectedLastDateFin = this.selectedDateFin
        // ******************* la difference entre deux date ************************
        let lastDateDebut = this.selectedDateDebut
          ? moment(this.selectedDateDebut, 'DD/MM/YYYY HH:mm').format(
              'YYYY/MM/DD'
            )
          : null
        // *******************************
        let firstDateFin = this.selectedDateFin
          ? moment(this.selectedDateFin, 'DD/MM/YYYY HH:mm').format(
              'YYYY/MM/DD'
            )
          : null
        var date1 = new Date(firstDateFin)
        var date2 = new Date(lastDateDebut)
        // différence de jours
        const diff = this.getBusinessDatesCount(date2, date1)
        this.days_Diff = parseInt(diff) - 1 == 0 ? 0 : parseInt(diff) - 1
        this.days_Diff_1 = 0
      }
      this.$refs['modal-center'].show()
    },
    getBusinessDatesCount(startDate, endDate) {
      let count = 0
      let curDate = +startDate
      while (curDate <= +endDate) {
        const dayOfWeek = new Date(curDate).getDay()
        const isWeekend = dayOfWeek === 0
        if (!isWeekend) {
          count++
        }
        curDate = curDate + 24 * 60 * 60 * 1000
      }
      return count
    },
    endRemove(rdv) {
      rdv.item.style.color = '#656565'
      rdv.item.style.backgroundColor = 'transparent'
    },
    removechoose(rdv) {
      rdv.item.style.color = '#dbdbdb'
      rdv.item.style.backgroundColor = '#dbdbdb'
    },
    async dragDropPlanningAMO(e, DayRow) {
      this.resetErrorProject()
      const payload = {}
      // Moved TR IN ONE TABLE POSE
      if (e && e.moved) {
        this.movedRdv = {
          moved: e.moved,
          DayRow: DayRow
        }
        // INCREMENTER INDEX DRAG AND DROP POUR IL COMPATIBLE AVEC INDEX DE TABLE AMO
        e.moved.oldIndex += 1
        e.moved.newIndex += 1
        // BOUCLE FOR POUR LA TABLE DE Pose
        for (let index = 0; index < DayRow.length; index++) {
          const tech = DayRow[index]
          // CAS UPDATE RDV
          if (
            e.moved.element &&
            e.moved.element.projet_tech &&
            e.moved.element.projet_tech.project_id &&
            e.moved.newIndex != tech.index
          ) {
            this.selectedDateDebut =
              e.moved && e.moved.element && e.moved.element.projet_tech.rdv_le
                ? moment(
                    e.moved.element.projet_tech.rdv_le,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('DD/MM/YYYY HH:mm')
                : null
            this.selectedDateFin =
              e.moved && e.moved.element && e.moved.element.projet_tech.fin_rdv
                ? moment(
                    e.moved.element.projet_tech.fin_rdv,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('DD/MM/YYYY HH:mm')
                : null
            //
            this.dataInjecterRDV = e.moved.element.projet_tech.rdv_le
              ? moment(
                  e.moved.element.projet_tech.rdv_le,
                  'YYYY-MM-DD HH:mm:ss'
                ).format('YYYY-MM-DD')
              : null
            //
            let field_group = [
              {
                key: 'date_rdv',
                value: this.selectedDateDebut ? this.selectedDateDebut : null
              },
              {
                key: 'hour_rdv',
                value: this.selectedDateDebut
                  ? moment(
                      this.selectedDateDebut.split(' ')[1],
                      'HH:mm'
                    ).format('HH:mm')
                  : null
              },
              {
                key: 'fin_rdv',
                value: this.selectedDateFin ? this.selectedDateFin : null
              },
              // FILIALE + AGENCE +AMO
              {
                key: 'technicien_id',
                value:
                  this.technician && this.technician.id
                    ? parseInt(this.technician.id)
                    : null
              },
              {
                key: 'agence_tech_id',
                value:
                  this.filialeAgence && this.filialeAgence.agence
                    ? parseInt(this.filialeAgence.agence)
                    : null
              },
              {
                key: 'team_tech_id',
                value:
                  this.filialeAgence && this.filialeAgence.filiale
                    ? parseInt(this.filialeAgence.filiale)
                    : null
              },
              {
                key: 'logistique_travaux',
                value:
                  tech && tech.projet_tech && tech.projet_tech.travaux
                    ? tech.projet_tech.travaux
                    : null
              },
              {
                key: 'type_travaux',
                value:
                  tech && tech.projet_tech && tech.projet_tech.type_travaux
                    ? tech.projet_tech.type_travaux
                    : null
              },
              {
                key: 'type_isolation',
                value:
                  tech && tech.projet_tech && tech.projet_tech.type_isolation
                    ? tech.projet_tech.type_isolation
                    : null
              }
            ]
            payload.entity = e.moved.element.projet_tech.project_id
            payload.field = field_group
            payload.value = field_group
            //  pour tester ce fields dans file projet js
            payload.field_group = true
            payload.planningAmo = true
            // update rdv de td
            this.bodyRdv = payload
            // OPEN MODAL
            this.openUpdateInjection(e.moved.element, null)
            this.$refs['modal-center'].show()
            this.row = DayRow
            break
          }
        }
      }
      // MOVED TR ENTRE TWO TABLE
      // *************************************************************
      if (e && e.added) {
        e.added.newIndex += 1
        e.added.element.remove = true
        this.movedRdv = {
          added: e.added,
          DayRow: DayRow
        }
        // cas add lead not vide
        if (
          e.added &&
          e.added.element &&
          e.added.element.projet_tech &&
          e.added.element.projet_tech.project_id
        ) {
          for (let index = 0; index < DayRow.length; index++) {
            const tech = DayRow[index]
            // add lead dans tr vide
            if (
              tech.index == e.added.newIndex &&
              tech.projet_tech &&
              tech.projet_tech.length == 0
            ) {
              localStorage.setItem('movedRdv', 0)
              this.$nextTick(() => {
                // update objet leads
                this.selectedDateDebut =
                  e.added &&
                  e.added.element &&
                  e.added.element.projet_tech.rdv_le
                    ? moment(
                        e.added.element.projet_tech.rdv_le,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('DD/MM/YYYY HH:mm')
                    : null
                this.selectedDateFin =
                  e.added &&
                  e.added.element &&
                  e.added.element.projet_tech.fin_rdv
                    ? moment(
                        e.added.element.projet_tech.fin_rdv,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('DD/MM/YYYY HH:mm')
                    : null
                //
                this.dataInjecterRDV = e.added.element.projet_tech.rdv_le
                  ? moment(
                      e.added.element.projet_tech.rdv_le,
                      'YYYY-MM-DD HH:mm:ss'
                    ).format('YYYY-MM-DD')
                  : null
                //
                let field_group = [
                  {
                    key: 'date_rdv',
                    value: this.selectedDateDebut
                      ? this.selectedDateDebut
                      : null
                  },
                  {
                    key: 'hour_rdv',
                    value: this.selectedDateDebut
                      ? moment(
                          this.selectedDateDebut.split(' ')[1],
                          'HH:mm'
                        ).format('HH:mm')
                      : null
                  },
                  {
                    key: 'fin_rdv',
                    value: this.selectedDateFin ? this.selectedDateFin : null
                  },
                  // FILIALE + AGENCE +AMO
                  {
                    key: 'technicien_id',
                    value:
                      this.technician && this.technician.id
                        ? parseInt(this.technician.id)
                        : null
                  },
                  {
                    key: 'agence_tech_id',
                    value:
                      this.filialeAgence && this.filialeAgence.agence
                        ? parseInt(this.filialeAgence.agence)
                        : null
                  },
                  {
                    key: 'team_tech_id',
                    value:
                      this.filialeAgence && this.filialeAgence.filiale
                        ? parseInt(this.filialeAgence.filiale)
                        : null
                  },
                  {
                    key: 'logistique_travaux',
                    value:
                      e.added.element.projet_tech &&
                      e.added.element.projet_tech.travaux
                        ? e.added.element.projet_tech.travaux
                        : null
                  },
                  {
                    key: 'type_travaux',
                    value:
                      e.added.element.projet_tech &&
                      e.added.element.projet_tech.type_travaux
                        ? e.added.element.projet_tech.type_travaux
                        : null
                  },
                  {
                    key: 'type_isolation',
                    value:
                      e.added.element.projet_tech &&
                      e.added.element.projet_tech.type_isolation
                        ? e.added.element.projet_tech.type_isolation
                        : null
                  }
                ]

                payload.entity = e.added.element.projet_tech.project_id
                payload.field = field_group
                payload.value = field_group
                //  pour tester ce fields dans file projet js
                payload.field_group = true
                payload.planningAmo = true
                // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                // Update numero index selon new index and planning date
                e.added.element.index = e.added.newIndex
                e.added.element.planningDate = tech.planningDate
                e.added.element.id = `row_index_${e.added.newIndex}_on`
                var myIndexAdded = DayRow.map(item => {
                  if (
                    item.index == e.added.newIndex &&
                    item.heure == e.added.element.heure &&
                    e.added.newIndex == e.added.element.index
                  ) {
                    return item.remove
                  }
                }).indexOf(false)
                if (myIndexAdded !== -1) {
                  DayRow.splice(myIndexAdded, 1)
                }
                this.bodyRdv = payload
                if (payload.field && payload.entity) {
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var deletedRdvOne = DayRow.map(item => {
                    if (
                      item.index == e.added.newIndex &&
                      item.projet_tech &&
                      item.projet_tech.length == 0
                    ) {
                      return item.index
                    }
                  }).indexOf(e.added.element.index)
                  if (deletedRdvOne !== -1) {
                    DayRow.splice(deletedRdvOne, 1)
                  }
                  this.openUpdateInjection(e.added.element, this.movedRdv)
                  this.$refs['modal-center'].show()
                  this.row = DayRow
                }
              })
              break
            } // add lead dans tr not vide
            if (
              tech.index == e.added.newIndex &&
              tech.projet_tech &&
              tech.projet_tech.project_id
            ) {
              localStorage.removeItem('movedRdv')
              let table = []
              for (let index = 0; index < DayRow.length; index++) {
                const element = DayRow[index]
                if (
                  element.index == e.added.newIndex &&
                  element.heure == e.added.element.heure &&
                  e.added.newIndex == e.added.element.index &&
                  element.projet_tech &&
                  element.projet_tech.project_id
                ) {
                  table.push(element)
                }
              }
              // add rd de meme heure et les deux remplir
              if (table && table.length == 2) {
                localStorage.setItem('movedRdv', 1)
                var myIndexAddedDeleted = DayRow.map(item => {
                  if (
                    item.index == e.added.newIndex &&
                    item.heure == e.added.element.heure &&
                    e.added.newIndex == e.added.element.index
                  ) {
                    return item.remove
                  }
                }).indexOf(true)
                if (myIndexAddedDeleted !== -1) {
                  DayRow.splice(myIndexAddedDeleted, 1)
                }
              }
              //
              else if (table && table.length == 1) {
                localStorage.setItem('movedRdv', 0)
                this.$nextTick(() => {
                  // update objet leads
                  this.selectedDateDebut =
                    e.added &&
                    e.added.element &&
                    e.added.element.projet_tech.rdv_le
                      ? moment(
                          e.added.element.projet_tech.rdv_le,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD/MM/YYYY HH:mm')
                      : null
                  this.selectedDateFin =
                    e.added &&
                    e.added.element &&
                    e.added.element.projet_tech.fin_rdv
                      ? moment(
                          e.added.element.projet_tech.fin_rdv,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD/MM/YYYY HH:mm')
                      : null
                  //
                  this.dataInjecterRDV = e.added.element.projet_tech.rdv_le
                    ? moment(
                        e.added.element.projet_tech.rdv_le,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('YYYY-MM-DD')
                    : null
                  //
                  // objet update rdv
                  //
                  let field_group = [
                    {
                      key: 'date_rdv',
                      value: this.selectedDateDebut
                        ? this.selectedDateDebut
                        : null
                    },
                    {
                      key: 'hour_rdv',
                      value: this.selectedDateDebut
                        ? moment(
                            this.selectedDateDebut.split(' ')[1],
                            'HH:mm'
                          ).format('HH:mm')
                        : null
                    },
                    {
                      key: 'fin_rdv',
                      value: this.selectedDateFin ? this.selectedDateFin : null
                    },
                    // FILIALE + AGENCE +AMO
                    {
                      key: 'technicien_id',
                      value:
                        this.technician && this.technician.id
                          ? parseInt(this.technician.id)
                          : null
                    },
                    {
                      key: 'agence_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.agence
                          ? parseInt(this.filialeAgence.agence)
                          : null
                    },
                    {
                      key: 'team_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.filiale
                          ? parseInt(this.filialeAgence.filiale)
                          : null
                    },
                    {
                      key: 'logistique_travaux',
                      value:
                        e.added.element.projet_tech &&
                        e.added.element.projet_tech.travaux
                          ? e.added.element.projet_tech.travaux
                          : null
                    },
                    {
                      key: 'type_travaux',
                      value:
                        e.added.element.projet_tech &&
                        e.added.element.projet_tech.type_travaux
                          ? e.added.element.projet_tech.type_travaux
                          : null
                    },
                    {
                      key: 'type_isolation',
                      value:
                        e.added.element.projet_tech &&
                        e.added.element.projet_tech.type_isolation
                          ? e.added.element.projet_tech.type_isolation
                          : null
                    }
                  ]
                  payload.entity = e.added.element.projet_tech.project_id
                  payload.field = field_group
                  payload.value = field_group
                  //  pour tester ce fields dans file projet js
                  payload.field_group = true
                  payload.planningAmo = true
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var myIndexAddeddeleted = DayRow.map(item => {
                    if (
                      item.index == e.added.newIndex &&
                      item.heure == e.added.element.heure &&
                      e.added.newIndex == e.added.element.index
                    ) {
                      return item.remove
                    }
                  }).indexOf(false)
                  if (myIndexAddeddeleted !== -1) {
                    DayRow.splice(myIndexAddeddeleted, 1)
                  }
                  this.bodyRdv = payload
                  if (payload.field && payload.entity) {
                    this.openUpdateInjection(e.added.element, this.movedRdv)
                    this.$refs['modal-center'].show()
                    this.row = DayRow
                    // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                    var deletedRdv = DayRow.map(item => {
                      if (
                        item.index == e.added.newIndex &&
                        item.heure == e.added.element.heure &&
                        e.added.newIndex == e.added.element.index &&
                        item.projet_tech &&
                        item.projet_tech.length == 0
                      ) {
                        return item.index
                      }
                    }).indexOf(e.added.element.index)
                    if (deletedRdv !== -1) {
                      DayRow.splice(deletedRdv, 1)
                    }
                  }
                })
              }
              // table vide tester par newindex and length leads
              else {
                localStorage.setItem('movedRdv', 1)
                for (let index = 0; index < DayRow.length; index++) {
                  const element = DayRow[index]
                  if (
                    element.index == e.added.newIndex &&
                    element.projet_tech &&
                    element.projet_tech.project_id
                  ) {
                    var myIndexAddedDeletedFalse = DayRow.map(item => {
                      //
                      if (
                        item.index == e.added.element.index &&
                        item.heure == e.added.element.heure
                      ) {
                        return item.remove
                      }
                    }).indexOf(true)
                    if (myIndexAddedDeletedFalse !== -1) {
                      DayRow.splice(myIndexAddedDeletedFalse, 1)
                    }
                    DayRow.sort(function compare(a, b) {
                      if (a.index < b.index) return -1
                      if (a.index > b.index) return 1
                      return 0
                    })
                  }
                }
              }
              break
            }
            // CAS ADDED LEAD DANS LA DERNIER ELEMENT DE TABLE ET SUPERIEUR INDEX TABLE
            if (e.added.newIndex >= 3 && tech.index == 2) {
              localStorage.removeItem('movedRdv')
              //  si lead d'index 14 est vide
              if (
                tech.index == 2 &&
                tech.projet_tech &&
                tech.projet_tech.length == 0
              ) {
                localStorage.setItem('movedRdv', 0)
                // update objet leads
                this.$nextTick(() => {
                  // update objet leads
                  this.selectedDateDebut =
                    e.added &&
                    e.added.element &&
                    e.added.element.projet_tech.rdv_le
                      ? moment(
                          e.added.element.projet_tech.rdv_le,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD/MM/YYYY HH:mm')
                      : null
                  this.selectedDateFin =
                    e.added &&
                    e.added.element &&
                    e.added.element.projet_tech.fin_rdv
                      ? moment(
                          e.added.element.projet_tech.fin_rdv,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('DD/MM/YYYY HH:mm')
                      : null
                  //
                  this.dataInjecterRDV = e.added.element.projet_tech.rdv_le
                    ? moment(
                        e.added.element.projet_tech.rdv_le,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format('YYYY-MM-DD')
                    : null
                  //

                  e.added.element.projet_tech.hour = e.added.element.projet_tech
                    .hour
                    ? moment(e.added.element.projet_tech.hour, 'HH:mm').format(
                        'HH:mm'
                      )
                    : null
                  e.added.element.heure = e.added.element.projet_tech.hour
                  e.added.element.index = 2
                  e.added.element.id = `row_index_${e.added.element.index}_on`
                  let field_group = [
                    {
                      key: 'date_rdv',
                      value: this.selectedDateDebut
                        ? this.selectedDateDebut
                        : null
                    },
                    {
                      key: 'hour_rdv',
                      value: this.selectedDateDebut
                        ? moment(
                            this.selectedDateDebut.split(' ')[1],
                            'HH:mm'
                          ).format('HH:mm')
                        : null
                    },
                    {
                      key: 'fin_rdv',
                      value: this.selectedDateFin ? this.selectedDateFin : null
                    },
                    // FILIALE + AGENCE +AMO
                    {
                      key: 'technicien_id',
                      value:
                        this.technician && this.technician.id
                          ? parseInt(this.technician.id)
                          : null
                    },
                    {
                      key: 'agence_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.agence
                          ? parseInt(this.filialeAgence.agence)
                          : null
                    },
                    {
                      key: 'team_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.filiale
                          ? parseInt(this.filialeAgence.filiale)
                          : null
                    },
                    {
                      key: 'logistique_travaux',
                      value:
                        e.added.element.projet_tech &&
                        e.added.element.projet_tech.travaux
                          ? e.added.element.projet_tech.travaux
                          : null
                    },
                    {
                      key: 'type_travaux',
                      value:
                        e.added.element.projet_tech &&
                        e.added.element.projet_tech.type_travaux
                          ? e.added.element.projet_tech.type_travaux
                          : null
                    },
                    {
                      key: 'type_isolation',
                      value:
                        e.added.element.projet_tech &&
                        e.added.element.projet_tech.type_isolation
                          ? e.added.element.projet_tech.type_isolation
                          : null
                    }
                  ]
                  //
                  payload.entity = e.added.element.projet_tech.project_id
                  payload.field = field_group
                  payload.value = field_group
                  //  pour tester ce fields dans file projet js
                  payload.field_group = true
                  payload.planningAmo = true
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var myIndex2 = DayRow.map(item => {
                    if (item.index == 2) {
                      return item.remove
                    }
                  }).indexOf(false)
                  if (myIndex2 !== -1) {
                    DayRow.splice(myIndex2, 1)
                  }
                  this.bodyRdv = payload
                  if (payload.field && payload.entity) {
                    this.openUpdateInjection(e.added.element, this.movedRdv)
                    this.$refs['modal-center'].show()
                    this.row = DayRow
                    // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                    var deletedRdvSupIndex = DayRow.map(item => {
                      if (
                        item.index == e.added.element.index &&
                        item.heure == e.added.element.heure &&
                        item.projet_tech &&
                        item.projet_tech.length == 0
                      ) {
                        return item.index
                      }
                    }).indexOf(e.added.element.index)
                    if (deletedRdvSupIndex !== -1) {
                      DayRow.splice(deletedRdvSupIndex, 1)
                    }
                  }
                })
              }
              //  si lead d'index 2 not vide
              else {
                localStorage.setItem('movedRdv', 1)
                // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                if (e.added.element.index != 2) {
                  var myIndexAddeddeletedRdv = DayRow.map(item => {
                    if (item.index == e.added.element.index) {
                      return item.remove
                    }
                  }).indexOf(true)
                  if (myIndexAddeddeletedRdv !== -1) {
                    DayRow.splice(myIndexAddeddeletedRdv, 1)
                  }
                } else {
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var myIndex3 = DayRow.map(item => {
                    if (
                      item.index == 2 &&
                      tech.projet_tech.project_id != item.projet_tech.project_id
                    ) {
                      return item.index
                    }
                  }).indexOf(2)
                  if (myIndex3 !== -1) {
                    DayRow.splice(myIndex3, 1)
                  }
                }
              }
              break
            }
          }
        }
      }
      // *************************************************************
      if (e && e.removed) {
        e.removed.oldIndex += 1
        e.removed.amo = this.technician.id
        localStorage.setItem('removed', JSON.stringify(e.removed))
        // cas add lead not vide
        if (
          e.removed &&
          e.removed.element &&
          e.removed.element.projet_tech &&
          e.removed.element.projet_tech.project_id
        ) {
          if (
            parseInt(localStorage.getItem('movedRdv')) == 0 &&
            e.removed.element.projet_tech &&
            e.removed.element.projet_tech.project_id
          ) {
            for (let index = 0; index < DayRow.length; index++) {
              const element = DayRow[index]
              // create new tr vide
              for (let index = 0; index < this.planning.length; index++) {
                if (
                  this.planning[index] &&
                  this.planning[index].id == this.technician.id
                ) {
                  {
                    if (
                      this.planning[index].days &&
                      this.planning[index].days.length
                    ) {
                      for (
                        let t = 0;
                        t < this.planning[index].days.length;
                        t++
                      ) {
                        for (
                          let j = 0;
                          j < this.planning[index].days[t].dayRows.length;
                          j++
                        ) {
                          if (
                            this.planning[index].days[t].date ==
                              element.planningDate &&
                            this.planning[index].days[t].dayRows &&
                            this.planning[index].days[t].dayRows.length == 1
                          ) {
                            let leadObject = {
                              active: e.removed.element.active,
                              colorBlue: e.removed.element.colorBlue,
                              heure: e.removed.element.heure,
                              id: e.removed.element.id,
                              index: e.removed.element.index,
                              leads: [],
                              planningDate: e.removed.element.planningDate,
                              projet_tech: [],
                              _lead: e.removed.element._lead,
                              tech_ids: parseInt(this.technician.id)
                            }
                            this.fetchObjectDayRowPlanning(leadObject)
                            this.$nextTick(async () => {
                              let dayRow = this.getObjectDayRowPlanning[0]
                              DayRow.push(dayRow)
                              DayRow.sort(function compare(a, b) {
                                if (a.index < b.index) return -1
                                if (a.index > b.index) return 1
                                return 0
                              })
                            })
                            break
                          }
                        }
                      }
                    }
                  }
                  break
                }
              }
            }
          } else if (
            parseInt(localStorage.getItem('movedRdv')) == 1 &&
            e.removed.element.projet_tech &&
            e.removed.element.projet_tech.project_id
          ) {
            DayRow.push(e.removed.element)
            DayRow.sort(function compare(a, b) {
              if (a.index < b.index) return -1
              if (a.index > b.index) return 1
              return 0
            })
          }
        }
      }
    },
    toggleOptimised() {
      if (
        this.currentUser.isSuper ||
        (this.canEdit &&
          (this.currentUser.type === 'user.admin' ||
            this.currentUser.type === 'user.resp.plan')) ||
        (this.currentUser.id = 687)
      ) {
        this.updateFieldOptimise({
          day: this.computedDay
        })
      }
    },
    handleCheckboxChange(field, row) {
      this.$nextTick(() => {
        this.changeCEEPhotoMeeting({
          lead: row.lead,
          field: field,
          value: row.lead[field] == 1 ? 0 : 1
        })
      })
    },
    handleSwitchChange(row) {
      this.updateRowStatus({
        row: row,
        day: this.computedDay
      })
    },
    async refreshPlanning() {
      await this.fetchPlanning()
    },
    handleInputChange(e, field, index) {
      this.simulation[index][field] = e.target.value
      this.$nextTick(async () => {
        this.simulated = true
        if (field == 'zipCode') {
          let origin = this.depot_zipCode
          if (this.user_zipCode) {
            origin = this.user_zipCode
          }
          if (
            index > 1 &&
            this.computedDay.dayRows &&
            this.computedDay.dayRows[index - 2].lead &&
            this.computedDay.dayRows[index - 2].lead.zipCode
          ) {
            origin = this.computedDay.dayRows[index - 2].lead.zipCode
          }
          this.simulation[index][
            'trajet_time_tech'
          ] = await this.getSimulatedTrajet({
            origin: origin,
            destination: this.simulation[index].zipCode
          })
        }

        this.simulation[index]['total'] = await this.getSimulatedTotalSurface(
          this.simulation[index]
        )

        this.simulation[index]['pose_time_tech'] = await this.getSimulatedPose(
          this.simulation[index]
        )

        const camion = await this.getSimulatedCamion(this.simulation[index])

        this.simulation[index]['camion'] = camion
          ? (Math.round((camion * 100 + Number.EPSILON) * 100) / 100).toFixed(
              0
            ) + '%'
          : ''

        if (this.simulation[index]['trajet_time_tech']) {
          this.simulation[index]['trajet_pose_tech_h_sum'] += this.simulation[
            index
          ]['trajet_time_tech']
          this.simulation['total_trajet'] =
            this.computedDay.total_trajet +
            this.simulation[index]['trajet_time_tech']
        }
        if (this.simulation[index]['pose_time_tech']) {
          this.simulation[index]['trajet_pose_tech_h_sum'] += this.simulation[
            index
          ]['pose_time_tech']
          this.simulation['total_pose'] =
            this.computedDay.total_pose +
            this.simulation[index]['pose_time_tech']
        }

        this.simulation['total_total_h'] =
          this.computedDay.total_total_h +
          this.simulation[index]['trajet_pose_tech_h_sum']

        this.simulation['total_s_planifie'] =
          this.computedDay.total_s_planifie +
          parseInt(
            this.simulation[index].surface_souffle
              ? this.simulation[index].surface_souffle
              : 0
          )
        this.simulation['total_d_planifie'] =
          this.computedDay.total_d_planifie +
          parseInt(
            this.simulation[index].surface_deroule
              ? this.simulation[index].surface_deroule
              : 0
          )
        this.simulation['total_r_planifie'] =
          this.computedDay.total_r_planifie +
          parseInt(
            this.simulation[index].surface_rampant
              ? this.simulation[index].surface_rampant
              : 0
          )
        this.simulation['total_m_planifie'] =
          this.computedDay.total_m_planifie +
          parseInt(
            this.simulation[index].surface_mur
              ? this.simulation[index].surface_mur
              : 0
          )
        this.simulation['total_p_planifie'] =
          this.computedDay.total_p_planifie +
          parseInt(
            this.simulation[index].surface_plafond
              ? this.simulation[index].surface_plafond
              : 0
          )

        this.simulation['total_pignon_planifie'] =
          this.computedDay.total_pignon_planifie +
          parseInt(
            this.simulation[index].surface_pignon
              ? this.simulation[index].surface_pignon
              : 0
          )
        this.simulation['total_vs_planifie'] =
          this.computedDay.total_vs_planifie +
          parseInt(
            this.simulation[index].surface_vide_sanitaire
              ? this.simulation[index].surface_vide_sanitaire
              : 0
          )
        this.simulation['iti_day'] =
          this.computedDay.iti_day +
          parseInt(
            this.simulation[index].surface_iti
              ? this.simulation[index].surface_iti
              : 0
          )
        this.simulation['total_total_m_planifie'] =
          this.computedDay.total_total_m_planifie +
          parseInt(
            this.simulation[index]['total']
              ? this.simulation[index]['total']
              : 0
          )
      })
    },
    resetSimulation() {
      this.simulation = {
        1: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        2: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        3: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        4: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        5: {
          zipCode: null,
          surface_souffle: null,
          surface_deroule: null,
          surface_rampant: null,
          surface_mur: null,
          surface_plafond: null,
          surface_pignon: null,
          surface_vide_sanitaire: null,
          surface_iti: null,
          trajet_time_tech: 0,
          total: 0,
          pose_time_tech: 0,
          camion: 0,
          trajet_pose_tech_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0,
        total_s_planifie: 0,
        total_d_planifie: 0,
        total_r_planifie: 0,
        total_m_planifie: 0,
        total_p_planifie: 0,
        total_pignon_planifie: 0,
        total_vs_planifie: 0,
        iti_day: 0,
        total_total_m_planifie: 0
      }
      this.simulated = false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getPlanningPipeline',
      'getProjectsProcessing',
      'getProjectsError',
      'getResponseUpdateProjectPlanning',
      'getProjectsWarning',
      'getProjectResponseUpdate',
      'getObjectDayRowPlanning'
    ]),
    computedStyle() {
      if (window.innerWidth <= 500) {
        return {
          width: window.innerWidth - 55 + 'px'
        }
      }
      return {}
    },
    computedHours() {
      return function(data) {
        if (data) {
          return data.split(':')[0]
        }
      }
    },
    popoverData() {
      return function(data) {
        if (data) {
          let debutFormat = data.rdv_le
            ? moment(data.rdv_le, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm')
            : ''
          let FinFormat = data.fin_rdv
            ? moment(data.fin_rdv, 'YYYY/MM/DD HH:mm').format(
                'DD/MM/YYYY HH:mm'
              )
            : ''

          return debutFormat && FinFormat ? `${debutFormat} - ${FinFormat}` : ''
        }
        if (JSON.parse(localStorage.getItem('pose'))) {
          let debutFormat = JSON.parse(localStorage.getItem('pose')).date_rdv
            ? moment(
                JSON.parse(localStorage.getItem('pose')).date_rdv,
                'YYYY/MM/DD HH:mm'
              ).format('DD/MM/YYYY HH:mm')
            : ''
          let FinFormat = JSON.parse(localStorage.getItem('pose')).fin_rdv
            ? moment(
                JSON.parse(localStorage.getItem('pose')).fin_rdv,
                'YYYY/MM/DD HH:mm'
              ).format('DD/MM/YYYY HH:mm')
            : ''
          return debutFormat && FinFormat ? `${debutFormat} - ${FinFormat}` : ''
        }
      }
    },
    computedValueDepartement() {
      return function(data) {
        if (data) {
          return data.substring(0, 2)
        }
      }
    },
    myList: {
      get() {
        return this.computedDay.dayRows
      },
      set(value) {
        return (this.computedDay.dayRows = value)
      }
    },
    colorStadeTech: function() {
      let status = []
      for (let index = 0; index < this.computedDay.dayRows.length; index++) {
        const element = this.computedDay.dayRows[index]
        if (
          element.lead &&
          element.lead.cpipeline_id &&
          element.lead.cstage_id &&
          element.lead.csubstage_id
        ) {
          this.getPlanningPipeline.forEach(pipeline => {
            if (pipeline.id == element.lead.cpipeline_id) {
              pipeline.stages.forEach(stages => {
                if (stages.id == element.lead.cstage_id) {
                  stages.csubstages.data.filter(substage => {
                    if (substage.id == element.lead.csubstage_id) {
                      status[element.index] = substage.color
                    }
                  })
                }
              })
            }
          })
        }
      }
      return status
    },
    computedDay: function() {
      if (this.day) {
        this.day.dayRows.forEach(row => {
          if (row && row.lead) {
            this.dossier[row.id] = row.lead.dossier
            this.photo[row.id] = row.lead.photo
            this.confirmation[row.id] = row.lead.confirmation
          }
        })
        return this.day
      }
      return {}
    }
  },
  filters: {
    formatMonth: function(value) {
      if ([1, 2, 3, 4, 5, 6, 7, 8, 9].includes(value)) {
        return '0' + value
      } else if (value >= 13) {
        return 1
      } else {
        return value
      }
    },
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value, 'DD-MM-YYYY').format(format)
    },
    time: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 10) return '00h0' + value
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    colorStatus: function(value) {
      switch (value) {
        case 'red':
          return 'redStatus'
        case 'orange':
          return 'orangeStatus'
        case 'yellow':
          return 'yellow'
        case 'green':
          return 'green'
      }
      return ''
    }
  },
  watch: {
    computedDay() {
      if (
        JSON.parse(localStorage.getItem('pose')) &&
        (JSON.parse(localStorage.getItem('pose')).amo_id == null ||
          JSON.parse(localStorage.getItem('pose')).date_rdv == null)
      ) {
        if (this.day && this.day.dayRows && this.day.dayRows.length) {
          for (let index = 0; index < this.day.dayRows.length; index++) {
            this.day.dayRows[index].colorBlue = true
          }
        }
      }
    }
  },
  mounted() {
    localStorage.removeItem('movedRdv')
    if (
      JSON.parse(localStorage.getItem('pose')) &&
      (JSON.parse(localStorage.getItem('pose')).amo_id == null ||
        JSON.parse(localStorage.getItem('pose')).date_rdv == null)
    ) {
      if (this.day && this.day.dayRows && this.day.dayRows.length) {
        for (let index = 0; index < this.day.dayRows.length; index++) {
          this.day.dayRows[index].colorBlue = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.day-summary-poseur {
  .handle {
    float: left;
    // padding-top: 8px;
    // padding-bottom: 8px;
  }
  table {
    &.grid {
      width: 190px;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      empty-cells: show;
      outline: 0;

      tr {
        height: 25px;

        th,
        td {
          position: relative;
          text-align: center;
          border: 1px solid #e8e8e8;
          outline: 0;
          vertical-align: middle;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 1px;

          &.off {
            background-color: #9c9c9c;
            border-color: #8e8e8e;
          }
        }
        th {
          font-size: 9px;
          font-weight: 700;
          background-color: #ededed;
          &.blue-header {
            background-color: #2dabe2;
            color: #fff;
          }
          &.grey-header {
            background-color: #6c757d;
            color: #fff;
          }

          &.icon-wrapper {
            padding: 0 2px;
            &.w13 {
              font-size: 13px;
            }
            &.w14 {
              font-size: 14px;
            }
            &.w15 {
              font-size: 15px;
              &.copy {
                cursor: pointer;
              }
            }
            .icon {
              &.rotating {
                animation: rotating 1s linear infinite;
              }
            }
          }

          &.action {
            position: relative;
            padding: 0 2px;
            cursor: pointer;
            &.optimised {
              background-color: #2dabe2;
              color: #fff;
            }
          }
        }
        td {
          font-size: 8px;
          &.light-grey-column {
            background-color: #dbdbdb;
            .visibility {
              visibility: hidden;
            }
          }
          &.light-grey-column-hours {
            background-color: #dbdbdb;
            border: 1px solid #dbdbdb;
            padding: 2px;
            margin: -1px;
            width: 104%;
            font-size: 9px;
            display: block;
            .visibility {
              visibility: hidden;
            }
            .color {
              color: #404346;
              font-weight: bold;
              background-color: #dbdbdb;
            }
            &.color-rdv {
              &:hover {
                background-color: #5ca9fc;
                border: 1px solid #5ca9fc;
                padding: 2px;
                color: white;
                margin: -1px;
                width: 104%;
                font-size: 9px;
                /* font-weight: bold; */
                display: block;
                cursor: pointer;
              }
            }
          }
          .planning-checkbox {
            padding-left: 27px;
          }
        }

        &.off {
          th,
          td {
            background-color: #9c9c9c;
            border-color: #9c9c9c;
          }
        }
        &.disabled {
          th,
          td {
            background-color: #dbdbdb;
            border-color: #dbdbdb;
          }
        }

        &.simulatable {
          td {
            position: relative;
            input {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              text-align: center;
            }

            .action {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #e73e01;
            }
          }
        }
        &.duplicate {
          background-color: #dbdbdb;
        }
        &:hover {
          color: #656565;
          background-color: #ededed;
        }
      }
    }
  }
  @keyframes rotating {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  @media screen and (max-width: 827px) {
    table {
      .color-title {
        background-color: #404346;
        color: white;
      }
      &.grid {
        tr {
          td {
            &.light-grey-column-hours {
              background-color: #ededed;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.day-summary-poseur {
  table {
    &.grid {
      td {
        .v-popover {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .trigger {
            display: block !important;
            position: relative;
            width: 100%;
            height: 100%;
          }
        }

        &.editable {
          position: relative;
          .edit {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 12px;
            justify-content: center;
            align-items: center;
            background: #dbdbdb;
            color: #2dabe2;
            z-index: 3;
            cursor: pointer;
          }

          .content {
            height: 100%;
            padding: 2px 2px 0;
            .info-surface {
              position: absolute;
              top: -7px;
              right: 1px;
              font-size: 7px;
              color: #2dabe2;
              z-index: 1;
            }
          }
          &:hover {
            .edit {
              display: flex;
            }
          }
        }

        &.commentable {
          position: relative;
          .v-popover {
            .trigger {
              .title-container {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-left: 15px;
                // .comment-tag {
                //   position: absolute;
                //   width: 20px;
                //   height: 10px;
                //   background: #ffe436;
                //   z-index: 9;
                //   display: block;
                //   top: -2px;
                //   right: -7px;
                //   transform: rotateZ(45deg);
                // }
                span {
                  outline: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                p {
                  margin-top: 0;
                  margin-bottom: 0;
                  margin-left: 2px;
                }
              }
            }
          }
        }

        &.colorable {
          .value {
            position: relative;
            z-index: 2;
          }
        }
      }
    }
    .color {
      font-weight: 700;
      background-color: #ededed;
    }
    .color-title {
      border: 1px solid #e8e8e8;
      width: 190px;
      margin-left: -1px;
      background-color: #ededed;
      padding: 3px;
      text-align: center;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2dabe2;
    background-color: #2dabe2;
  }
}
#modal-confirm-rdv {
  .postion-btn {
    margin-top: 7px;
    float: right;
  }
}
#modal-center {
  .datepicker-container {
    .datepicker-controls {
      height: 50px;
    }
  }
  .week-days {
    height: 23px;
    text-transform: capitalize;
  }
  .datepicker-container {
    .datepicker-days {
      .datepicker-day {
        height: 33px;
      }
    }
  }
  .datepicker-buttons-container {
    margin-top: -50px;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 675px;
      margin: 1.75rem auto;
    }
  }
  @media (min-width: 576px) {
    .modal-dialog-centered {
      min-height: calc(68% - 3.5rem);
    }
  }
  .content-injection {
    .gras {
      font-weight: bold;
    }
    .date-time-picker {
      width: 62%;
      height: 21px;
      margin: -5px 7px;
      text-align: left;
      font-size: 15px;
      border-radius: 4px;
      position: relative;
    }
    .field-input {
      height: 30px;
      min-height: 30px;
    }
    .flex {
      display: flex;
      // align-items: center;
    }
    .margin {
      margin: 11px 0px;
    }
    .margin-div {
      margin-bottom: 7px;
    }
  }
  .action {
    text-align: end;
    margin-top: 18px;
    margin-bottom: -7px;
  }
  .warning {
    color: #f39b0e;
  }
}
</style>
