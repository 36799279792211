<template>
  <div class="technician-poseur">
    <LoadingComponent v-if="computedData.loading" :styleLoadingTech="true" />
    <div v-else-if="estEcranBureauHeader && !computedData.loading" class="tech">
      {{ computedData.name }}
    </div>
    <div class="day" :style="computedStyle">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[0]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
        :filialeAgence="filialeAgence"
        :planning="planningTech"
        :dayCalander="getRelativeDay(dayCalander, 0)"
        :estEcranBureauHeader="estEcranBureauHeader"
      />
    </div>
    <div class="day" :style="computedStyle">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[1]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
        :filialeAgence="filialeAgence"
        :planning="planningTech"
        :dayCalander="getRelativeDay(dayCalander, 1)"
        :estEcranBureauHeader="estEcranBureauHeader"
      />
    </div>
    <div class="day" :style="computedStyle">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[2]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
        :filialeAgence="filialeAgence"
        :planning="planningTech"
        :dayCalander="getRelativeDay(dayCalander, 2)"
        :estEcranBureauHeader="estEcranBureauHeader"
      />
    </div>
    <div class="day" :style="computedStyle">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[3]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
        :filialeAgence="filialeAgence"
        :planning="planningTech"
        :dayCalander="getRelativeDay(dayCalander, 3)"
        :estEcranBureauHeader="estEcranBureauHeader"
      />
    </div>
    <div class="day" :style="computedStyle">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[4]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
        :filialeAgence="filialeAgence"
        :planning="planningTech"
        :dayCalander="getRelativeDay(dayCalander, 4)"
        :estEcranBureauHeader="estEcranBureauHeader"
      />
    </div>
    <div class="day" :style="computedStyle">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[5]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
        :filialeAgence="filialeAgence"
        :planning="planningTech"
        :dayCalander="getRelativeDay(dayCalander, 5)"
        :estEcranBureauHeader="estEcranBureauHeader"
      />
    </div>
    <!--   <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[5]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <div class="day">
      <DaySummary
        :loading="computedData.loading"
        :canEdit="canEdit"
        :month_id="month_id"
        :day="computedData.days[6]"
        :technician="computedData"
        :depot_zipCode="depot_zipCode"
        :user_zipCode="computedData.zipcode"
      />
    </div>
    <!-->
  </div>
</template>

<script>
import DaySummary from '@/views/PlanningPoseurs/DaySummary.vue'
import LoadingComponent from '@/components/ui/LoadingComponent'
import Moment from 'moment'

export default {
  name: 'PlanningPoseurPlanningRow',
  components: { DaySummary, LoadingComponent },
  props: {
    data: {
      required: true
    },
    month_id: {
      required: true
    },
    depot_zipCode: {
      required: true
    },
    canEdit: {
      default: false
    },
    filialeAgence: {
      required: true
    },
    planningTech: {
      required: true
    },
    dayCalander: {
      required: true
    },
    estEcranBureauHeader: {
      required: false
    }
  },
  computed: {
    computedStyle() {
      if (window.innerWidth <= 827 && window.innerWidth > 500) {
        return {
          height: 105 + 'px'
        }
      }
      if (window.innerWidth <= 500) {
        return {
          'margin-right': window.innerWidth - 265 + 'px',
          height: 103 + 'px'
        }
      }
      return { 'margin-right': -26 + 'px', height: 75 + 'px' }
    },
    getRelativeDay() {
      return function(day, inc) {
        let date = ''
        if (day) {
          date = Moment(day, 'YYYY-MM-DD')
            .add(inc, 'days')
            .format('DD-MM-YYYY')
        }
        return date
      }
    },
    computedData: function() {
      if (this.data) {
        return this.data
      }
      return { loading: true }
    }
  }
}
</script>

<style lang="scss" scoped>
.technician-poseur {
  display: inline-flex;
  // margin-bottom: 15px;
  margin-bottom: 3px;
  .tech {
    // padding: -281px;
    /* width: 119%; */
    width: 100px;
    text-align: center;
    // border: 1px solid #eaeaea;
    font-size: 12px;
    padding-top: 25px;
    margin-top: 25px;
    margin-right: 3px;
    margin-bottom: -12px;
    // background-color: #dbdbdb;
    background-color: white;
  }
  .day {
    display: block;
    text-align: center;
    width: 220px;
    height: 75px;
    padding: 10px 0;
    margin-right: -26px;
    font-size: 10px;
    font-weight: bold;
    // border: 1px solid rgba(0, 0, 0, 0.08);
    // background-color: #f6f6f6;
    color: #656565;

    box-sizing: border-box;
    // &.week {
    //   margin-right: 20px;
    //   min-width: 280px;
    //   box-shadow: 4px 0px 6px 0px #dadada;
    //   z-index: 2;
    //   position: sticky;
    //   left: 0;
    // }
  }
}
</style>
